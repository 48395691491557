// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-methodik-jsx": () => import("./../../../src/pages/methodik.jsx" /* webpackChunkName: "component---src-pages-methodik-jsx" */),
  "component---src-templates-city-jsx": () => import("./../../../src/templates/City.jsx" /* webpackChunkName: "component---src-templates-city-jsx" */)
}

